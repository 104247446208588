import React from 'react';
import { Grid,Box,Grow,Slide,Link,Button } from '@mui/material';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import { useInView } from 'react-intersection-observer'

const SpeakToProductHeader = ()=> {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
        <WideContainer>
            <div className='center-align'>
                <Grid container className='basic-header-container usecases-background-cover'>
                    <Slide timeout={{ enter: 1200 }} in={inView} direction="right" container={containerRef.current}>
                        <Grid item xs={12} sm={12} md={6} lg={5} className='center-align'>
                            <Grid className='monetize-ai-header-title' pl={{xs:0,md:4}} maxWidth='500px'>
                                <h1>Speak to product</h1>
                                <Grid py={2}>
                                    <p>Younet is introducing "Speak to Product" feature for businesses! </p>
                                    <br/>
                                    <p>Now, with an AI model enriched with product descriptions, manuals, and FAQs, Younet can generate a QR code that can be placed on product packaging, allowing customers to interact with the AI forsupport and guidance anytime, anywhere.</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Slide>
                    <Grow timeout={{ enter: 1200 }} in={inView}>
                        <Grid item xs={12} sm={12} md={6} lg={7} className='center-align'>
                            <Grid className='center-align' py={3}>
                                <img loading='lazy' width='100%' src='/images/products/speak-to-product/header-pic.png' 
                                srcSet='/images/products/speak-to-product/header-pic.png 1x, /images/products/speak-to-product/header-pic@2x.png 2x'
                                alt=''/>
                            </Grid>
                        </Grid>
                    </Grow>
                    <div className='long-observer' ref={inViewRef}></div>
                </Grid>
            </div>
        </WideContainer>
    );
}

export default SpeakToProductHeader;