import React,{useEffect} from 'react';
import 'components/products/products.css'
import SpeakToProductHeader from 'components/products/SpeakToProductHeader';
import SpeakToProductArticle from 'components/products/SpeakToProductArticle';

const SpeakToProduct=(props)=> {
    useEffect(()=>{
        window.scrollTo(0, 0);
    })
    return (
        <div>
            <SpeakToProductHeader/>
            <SpeakToProductArticle/>
        </div>
    );
}

export default SpeakToProduct;