import React from 'react';
import { Grid, Grow, Slide, Link } from '@mui/material';
import SupportedCompanies from 'components/mui/cards/SupportedCompanies';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import ContentRowA from './ContentRowA';
import ContentRowB from './ContentRowB';
import GetStartedCard from 'components/mui/cards/getStartedCard';
import YoutubeVideo from './YoutubeVideo';

const SpeakToProductArticle=()=> {
    const rows = [
        {
            title: 'AI-powered product knowledge',
            content: 'This revolutionary feature transforms customer support and problem-solving by leveraging product-specific knowledge and access to billions of AI datasets, delivering instant and valuable insightsabout the product or service.',
            picSrc: '/images/products/speak-to-product/figure-1.png',
            picSrc2x:'/images/products/speak-to-product/figure-1@2x.png',
        },
        {
            title: 'Fast integration without coding',
            content: 'Our solution offers fast integration without the need for any coding, allowing businesses to seamlessly implement the technology into their existing systems. This eliminates the need for complex development processes, saving both time and resources while ensuring a smooth transition. ',
            picSrc: '/images/products/speak-to-product/figure-2.png',
            picSrc2x:'/images/products/speak-to-product/figure-2@2x.png',
        },
        {
            title:'Boosting efficiency and availability',
            content:'Using the Speak-to-product will significantly save clients‘ time by providing instant responses and solutions, eliminating the need for lengthy manual searches or customer service wait times. \n\nAdditionally, it increases availability by offering 24/7 support, ensuring clients can access valuable insights and assistance whenever they need it, leading to enhanced productivity and satisfaction.',
            picSrc:'/images/products/speak-to-product/figure-3.png',
            picSrc2x:'/images/products/speak-to-product/figure-3@2x.png',
        },
        // {
        //     title: 'Flexible Payment Options',
        //     content: 'Choose how you receive payments from subscribers with options like PayPal or Wire Transfer, ensuring convenience for both you and your subscribers.',
        //     picSrc: '/images/products/monetize-ai/figure-4.png',
        //     picSrc2x:'/images/products/monetize-ai/figure-4@2x.png',
        // },
    ]

    return (
        <div>
            <WideContainer>
                {/* <Grid container sx={{ py: { md: 3 } }} flexDirection='column' className='center-align ai-bot-video-background-cover'>
                    <Grid pt={4} pb={2} px={2}>
                    <h1 style={{textAlign:'center'}}>Turn Your Expertise into Revenue</h1>
                    </Grid>
                    <Grid className='overview-header-pic center-align'>
                        <Grid width='90vw' maxWidth='840px'>
                        <YoutubeVideo videoHeight='472.5' videoWidth='840'videoId='uLHfgWJpaqk' poster='/images/products/monetize-ai/monetize-ai-poster.jpeg'/>
                        </Grid>
                    </Grid>
                </Grid> */}
                <Grid container sx={{ pt: { xs:2, md: 3 } }}>
                <SupportedCompanies/>
                </Grid>
                <Grid container sx={{ py: { xs:2, md: 3 } }} className='center-align'>
                    <ContentRowA title={rows[0].title} contents={rows[0].content} picSrc={rows[0].picSrc} picSrc2x={rows[0].picSrc2x} button={false} long={false} />
                </Grid>
                <Grid container sx={{ py: { xs:2, md: 3 } }} className='center-align'>
                    <ContentRowB title={rows[1].title} contents={rows[1].content} picSrc={rows[1].picSrc} picSrc2x={rows[1].picSrc2x} button={false} long={false} />
                </Grid>
                <Grid container sx={{ py: { xs:2, md: 3 } }} className='center-align'>
                    <ContentRowA title={rows[2].title} contents={rows[2].content} picSrc={rows[2].picSrc} picSrc2x={rows[2].picSrc2x} button={false} long={false} />
                </Grid>
                {/* <Grid container sx={{ py: { xs:2, md: 3 } }} className='center-align'>
                    <ContentRowB title={rows[3].title} contents={rows[3].content} picSrc={rows[3].picSrc} picSrc2x={rows[3].picSrc2x} button={false} long={false} />
                </Grid> */}
                <Grid px={2} mb={{ xs:4, md: 8 } }>
                    <GetStartedCard/>
                </Grid>
            </WideContainer>
        </div>
    );
}

export default SpeakToProductArticle;